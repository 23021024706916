import { NgClass, NgTemplateOutlet } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
  signal,
  computed,
} from "@angular/core";
import { Router } from "@angular/router";
import { IpCountryService } from "@app/services/ip-country.service";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-w";
import { LanguageService } from "src/app/core/services/language.service";
import { Book } from "src/app/models/shared-models/book.model";
import {
  CreativeCommonsLicenseType,
  CreativeCommonsLicenseTypeIcon,
  CreativeCommonsLicenseTypeTranslation,
} from "src/app/models/shared-models/license-type-creative-commons.enum";
import { LicenseType } from "src/app/models/shared-models/license-type.enum";
import { ProductSupply } from "src/app/models/shared-models/product-supply.model";
import { ProductService } from "src/app/services/product.service";
import { ShoppingCartService } from "src/app/services/shopping-cart.service";
import { PopupComponent } from "../../../components/popup/popup.component";

@Component({
  selector: "app-buy-button",
  templateUrl: "./buy-button.component.html",
  styleUrls: ["./buy-button.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    InlineSVGModule,
    PopupComponent,
    TranslateModule,
  ],
})
export class BuyButtonComponent implements OnInit {
  languageService = inject(LanguageService);
  shoppingCartService = inject(ShoppingCartService);
  private router = inject(Router);
  private productService = inject(ProductService);
  private ipCountry = inject(IpCountryService);

  @Input() productSupply!: ProductSupply;
  @Input() book!: Book;
  preOrderWeeks = 8;
  country = signal<string | undefined>(undefined);

  ccLicenseType = CreativeCommonsLicenseType;
  ccLicenseTypeTranslation = CreativeCommonsLicenseTypeTranslation;
  ccLicenseTypeIcon = CreativeCommonsLicenseTypeIcon;

  @ViewChild("electronic", { static: true })
  electronicTemplate!: TemplateRef<any>;
  @ViewChild("printBook", { static: true })
  printBookTemplate!: TemplateRef<any>;
  @ViewChild("openAccessBook", { static: true })
  openAccessBookTemplate!: TemplateRef<any>;
  @ViewChild("notAvailable", { static: true })
  notAvailableTemplate!: TemplateRef<any>;

  constructor() {}

  isNorthAmericanUser = computed(() => {
    return ["US", "CA"].includes(this.country() ?? "");
  });

  async ngOnInit(): Promise<void> {
    // Update this method to set the country signal
    try {
      const countryCode = await this.ipCountry.getCountry();
      this.country.set(countryCode);
    } catch (error) {
      console.error("Failed to get country code", error);
      this.country.set("");
    }
  }

  openOnDeGrutyer() {
    const deGruyterUrl = `https://www.degruyter.com/document/isbn/${this.productSupply.isbn}/html`;
    window.open(deGruyterUrl, "_blank", "noopener");
  }

  getTemplate(): TemplateRef<any> {
    if (this.isNotAvailable()) return this.notAvailableTemplate;

    if (this.isOpenAccess()) return this.openAccessBookTemplate;

    if (this.isElectronic()) return this.electronicTemplate;
    return this.printBookTemplate;
  }

  isOutOfPrint(): boolean {
    const code = parseInt(this.productSupply.availability ?? "99");
    return code >= 40 && code <= 49;
  }

  isNotAvailable(): boolean {
    if (this.isOutOfPrint()) return true;

    // if the publishing date is more than 4 weeks in the future
    try {
      const diffInWeeks = this.productService.getPublishingDateWeeksDifference(
        this.productSupply.publishingDate,
      );
      return diffInWeeks > this.preOrderWeeks;
    } catch {
      return true;
    }
  }

  isPreorder(): boolean {
    // if the publishing date is in the future but not more than 4 weeks
    try {
      const diffInWeeks = this.productService.getPublishingDateWeeksDifference(
        this.productSupply.publishingDate,
      );
      return diffInWeeks > 0 && diffInWeeks <= this.preOrderWeeks;
    } catch {
      return false;
    }
  }

  addToCart() {
    this.shoppingCartService.addToCart(this.book, this.productSupply);
    this.router.navigate(["/cart"]);
  }

  isElectronic() {
    return this.productSupply?.format.toLowerCase() == "electronic";
  }

  downloadPDF() {
    const doi = this.book.bookData?.doi || `10.1515/${this.productSupply.isbn}`;
    const downloadOpenAccessUrl = `https://www.degruyter.com/document/doi/${doi}/pdf`;
    window.open(downloadOpenAccessUrl, "_blank", "noopener");
  }

  isOpenAccess(): boolean {
    return this.productSupply?.license == LicenseType.OpenAccess;
  }

  redirectToIndiePubs(): void {
    const indiePubsUrl = `https://add-to-cart-2.supadu.com/add-to-cart?isbn=${this.productSupply.isbn}?&client=indiepubs-de-gruyter`;
    window.open(indiePubsUrl, "_blank", "noopener");
  }
}
