<div
  class="container"
  [ngClass]="{
    'available-soon': isNotAvailable(),
  }"
>
  <ng-container [ngTemplateOutlet]="getTemplate()"> </ng-container>
</div>

<ng-template #notAvailable>
  <div class="btn-spacer">
    <ng-container [ngTemplateOutlet]="info"></ng-container>
  </div>
  <div class="label mono tiny pointer available-soon">
    {{
      (isOutOfPrint() ? "BOOK.info-outOfPrint" : "BOOK.info-4wk-12mo")
        | translate
    }}
  </div>
</ng-template>

<ng-template #preorderInfo>
  @if (isNorthAmericanUser()) {
    <div
      class="label mono tiny pointer"

    >
      {{ "BOOK.preorder_on_indiepubs" | translate }}
    </div>
  } @else {
    <div class="label mono tiny pointer">
      {{ "BOOK.info-0-4wk" | translate }}
      <!-- {{ "BOOK.availableOn" | translate }}
      {{ languageService.formatDate(productSupply.publishingDate, false) }} -->
      <!-- <span inlineSVG="img/icon-info-small.svg"></span> -->
    </div>
  }
  
</ng-template>

<ng-template #preorderInfoElectronic>
  <div class="label mono tiny pointer">
    {{ "BOOK.info-0-4wk" | translate }}
    <!-- {{ "BOOK.availableOn" | translate }}
    {{ languageService.formatDate(productSupply.publishingDate, false) }} -->
    <!-- <span inlineSVG="img/icon-info-small.svg"></span> -->
  </div>
</ng-template>

<ng-template #electronic>
  <div class="btn" (click)="openOnDeGrutyer()">
    <div class="icon" inlineSVG="img/icon-redirect.svg"></div>
    <ng-container [ngTemplateOutlet]="info"></ng-container>
  </div>
  <ng-container
    [ngTemplateOutlet]="isPreorder() ? preorderInfoElectronic : electronicInfo"
  ></ng-container>
  <ng-template #electronicInfo>
    <div
      class="label mono tiny pointer"
      (click)="buyOnDeGrutyerPopup.show = true"
    >
      {{ "BOOK.buy_on_degruyter" | translate }}
      <span inlineSVG="img/icon-info-small.svg"></span>
    </div>
  </ng-template>
</ng-template>

<ng-template #printBook>
  @if (isNorthAmericanUser()) {
    <div class="btn" (click)="redirectToIndiePubs()">
      <div class="icon" inlineSVG="img/icon-redirect.svg"></div>
      <ng-container [ngTemplateOutlet]="indiePubsInfo"></ng-container>
    </div>
  } @else {
    <div class="btn" (click)="addToCart()">
      <div class="icon" inlineSVG="img/add-to-cart.svg"></div>
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    </div>
  }
  <ng-container
    [ngTemplateOutlet]="isPreorder() ? preorderInfo : printInfo"
  ></ng-container>
  <ng-template #printInfo>
    @if (isNorthAmericanUser()) {
      <div
        class="label mono tiny pointer"

      >
        {{ "BOOK.buy_on_indiepubs" | translate }}
      </div>
    } @else {
      <div
        class="label mono tiny pointer"
        (click)="freeShippingPopup.show = true"
      >
        {{ "BOOK.free_shipping" | translate }}
      </div>
    }
  </ng-template>
</ng-template>

<ng-template #openAccessBook>
  <div class="btn" (click)="downloadPDF()">
    <div class="icon" inlineSVG="img/download-ebook.svg"></div>
    <ng-container [ngTemplateOutlet]="info"></ng-container>
  </div>
  <ng-container
    [ngTemplateOutlet]="isPreorder() ? preorderInfo : openAccessInfo"
  ></ng-container>
  <ng-template #openAccessInfo>
    <div class="label mono tiny pointer" (click)="ccPopup.show = true">
      {{ "BOOK.view_license" | translate }}
      <span inlineSVG="img/icon-info-small.svg"></span>
    </div>
  </ng-template>
</ng-template>

<ng-template #info>
  <div class="info">
    <div class="format">
      {{ "BOOK.format_" + productSupply.format.toLowerCase() | translate }}
    </div>
    <div class="price">
      {{ shoppingCartService.getPriceString(productSupply) }}
    </div>
  </div>
</ng-template>

<ng-template #indiePubsInfo>
  <div class="info">
    <div class="format">
      {{ "BOOK.format_" + productSupply.format.toLowerCase() | translate }}
    </div>
    <div class="price">
      {{ shoppingCartService.getPriceString(productSupply) }}
    </div>
  </div>
</ng-template>

<app-popup
  #ccPopup
  titleKey="BOOK.view_license_headline"
  [icon]="
    productSupply.creativeCommonsLicense
      ? ccLicenseTypeIcon[productSupply.creativeCommonsLicense]
      : ''
  "
>
  <div class="mono small">
    @if (productSupply.creativeCommonsLicense) {
      <div
        class="pre"
        [innerHTML]="
          ccLicenseTypeTranslation[productSupply.creativeCommonsLicense]
            | translate
        "
      ></div>
    }
  </div>
</app-popup>

<app-popup
  #freeShippingPopup
  titleKey="BOOK.free_shipping_headline"
  icon="img/icon-shipping-info.svg"
>
  <div
    class="mono small pre"
    [innerHTML]="'BOOK.free_shipping_infotext' | translate"
  ></div>
</app-popup>

<app-popup #buyOnDeGrutyerPopup titleKey="BOOK.buy_on_degruyter_headline">
  <div
    class="mono small pre"
    [innerHTML]="'BOOK.buy_on_degruyter_infotext' | translate"
  ></div>
</app-popup>
